import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Pagination, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Orders.css';

const Orders = ({ statusId, title }) => {
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [sortOrder, setSortOrder] = useState('id'); // Por defecto, se ordena por ID
  const navigate = useNavigate();

  useEffect(() => {
    fetchTotalOrders();
    setCurrentPage(1);
  }, [statusId]);

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage, statusId, sortOrder]);

  const fetchTotalOrders = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders`, {
      params: { status_id: statusId, page: 1 }
    })
      .then(response => {
        setTotal(response.data.total);
      })
      .catch(error => {
        console.error('Error fetching total orders:', error);
      });
  };

  const convertToDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const isToday = (dateString) => {
    if (!dateString) return false;  // Si no hay fecha, devolvemos false directamente
    const today = new Date();
    const [day, month, year] = dateString.split('/');
    return (
      today.getDate() === parseInt(day, 10) &&
      today.getMonth() + 1 === parseInt(month, 10) &&
      today.getFullYear() === parseInt(year, 10)
    );
  };

  const fetchOrders = (page) => {
    setLoading(true);
    return axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders`, {
      params: { 
        status_id: statusId, 
        page
      }
    })
      .then(response => {
        let fetchedOrders = response.data.orders;

        // Ordenamiento basado en el estado actual de sortOrder
        if (sortOrder === 'scheduled_date') {
          fetchedOrders = fetchedOrders.sort((a, b) => {
            const dateA = convertToDate(extractScheduledDate(a.customer_message));
            const dateB = convertToDate(extractScheduledDate(b.customer_message));
            return dateB - dateA; // Orden descendente
          });
        }

        setOrders(fetchedOrders);
        setLoading(false);

        // Ahora llamamos a fetchShippingMethod para cada orden
        fetchedOrders.forEach(async (order) => {
          const shippingMethod = await fetchShippingMethod(order.id);
          setOrders(prevOrders =>
            prevOrders.map(o => 
              o.id === order.id ? { ...o, shipping_method: shippingMethod } : o
            )
          );
        });
      })
      .catch(error => {
        console.error('Error fetching orders:', error);
        setLoading(false);
      });
  };

  const fetchShippingMethod = async (orderId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${orderId}/consignments`);
      if (response.data.shipping && response.data.shipping.length > 0) {
        return response.data.shipping[0].shipping_method;
      }
    } catch (error) {
      console.error('Error fetching shipping method:', error);
    }
    return 'No disponible';
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`, { state: { from: title.toLowerCase(), sortOrder } }); // Pasar sortOrder al cambiar de vista
  };

  const formatCurrency = (amount) => {
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  const extractScheduledDate = (message) => {
    if (!message) {
      return null;
    }
    const match = message.match(/Fecha programada:\s*(\d{2}\/\d{2}\/\d{4})/);
    return match ? match[1] : null;
  };

  const handleStatusChange = (orderId, newStatusId) => {
    axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${orderId}`, {
      status_id: newStatusId
    })
    .then(response => {
      fetchOrders(currentPage); // Refresh orders list after status update
    })
    .catch(error => {
      console.error('Error updating order status:', error);
    });
  };

  const getStatusOptions = (order) => {
    const currentStatus = { value: order.status_id, label: order.custom_status || order.status };
    let nextStatusOptions = [];

    switch (statusId) {
        case 7: // Orden Creada
            nextStatusOptions = [{ value: 1, label: 'En Proceso' }];
            break;
        case 1: // En Proceso
            nextStatusOptions = [{ value: 9, label: 'Preparando pedido' }];
            break;
        case 9: // Preparando envío
            if (order.payment_method === 'Pagar a la entrega') {
                nextStatusOptions = [{ value: 8, label: 'Listo y en espera de recolección' }];
            } else if (order.payment_method === 'Tarjeta de Crédito') {
                nextStatusOptions = [
                    { value: 8, label: 'Pagado y en espera de recolección' },
                    { value: 11, label: 'Pagado y en proceso de envío' }
                ];
            }
            break;
        case 8: // Pagado y en espera de recolección
        case 12: // Listo y en espera de recolección
            nextStatusOptions = [{ value: 10, label: 'Entregado' }];
            break;
        case 11: // Pagado y en proceso de envío
            nextStatusOptions = [
                { value: 2, label: 'En Ruta' }
            ];
            break;
        case 2: // En Ruta
            nextStatusOptions = [{ value: 10, label: 'Entregado' }];
            break;
        default:
            break;
    }

    // Devuelve el estado actual y las opciones futuras permitidas
    return [currentStatus, ...nextStatusOptions];
  };

  const handleRefresh = () => {
    setUpdating(true);
    fetchTotalOrders();
    fetchOrders(currentPage).then(() => setUpdating(false));
  };

  const handleSortById = () => {
    setSortOrder('id');
  };

  const handleSortByScheduledDate = () => {
    setSortOrder('scheduled_date');
  };

  const totalPages = Math.ceil(total / 25);

  return (
    <div className="container mt-4">
      {(loading || updating) && (
        <div className="overlay">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>{title}</h1>
        <div className="d-flex">
          <Button 
            variant={sortOrder === 'id' ? 'warning' : 'light'} 
            onClick={handleSortById} 
            style={{ marginRight: '12px' }}
          >
            Ordenar por ID
          </Button>
          <Button 
            variant={sortOrder === 'scheduled_date' ? 'warning' : 'light'} 
            onClick={handleSortByScheduledDate} 
            style={{ marginRight: '12px' }}
          >
            Ordenar por Fecha Programada
          </Button>
          <Button variant="secondary" onClick={handleRefresh}>Actualizar</Button>
        </div>
      </div>
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Orden ID</th>
              <th>Nombre</th>
              <th>Cliente Beth Yosef</th>
              <th>Nombre de facturación</th>
              <th>Total</th>
              <th>Status</th>
              <th>Fecha programada de envío</th>
              <th>Tipo de envío</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(orders) && orders.length > 0 ? (
              orders.map(order => {
                const scheduledDate = extractScheduledDate(order.customer_message);
                const isScheduledForToday = isToday(scheduledDate);

                return (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{`${order.billing_address.first_name}`}</td>
                    <td>{order.billing_address.last_name}</td>
                    <td>{`${order.billing_address.first_name} ${order.billing_address.last_name}`}</td>
                    <td>{formatCurrency(order.total_inc_tax)}</td>
                    <td>
                      {order.status_id === 9 || order.status_id === 10 ? ( // Verificar si el estado es Preparando envío o Entregado
                        <span>{order.custom_status || order.status}</span>
                      ) : (
                        <select
                          value={order.custom_status || order.status}
                          onChange={(e) => handleStatusChange(order.id, e.target.value)}
                        >
                          {getStatusOptions(order).map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                    <td
                      style={{
                        backgroundColor: isScheduledForToday ? '#ffc10773' : 'transparent',
                      }}
                    >
                      {scheduledDate}
                    </td>
                    <td>
                      {order.shipping_method ? (
                        order.shipping_method
                      ) : (
                        <Spinner animation="border" size="sm" />
                      )}
                    </td>
                    <td>
                      <Button variant="primary" onClick={() => handleOrderClick(order.id)}>Detalle</Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="9" className="text-center">No hay órdenes en este estado.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination>
        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
        {[...Array(totalPages).keys()].map(page => (
          <Pagination.Item
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => setCurrentPage(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    </div>
  );
};

export default Orders;
